"use client";
import React from "react";
import {
  AboutContainer,
  AboutContent,
  AboutRight,
  BgImgWrap,
  ContentContainer,
  Layout,
  LearnMoreBtn,
  RoundShape,
  TP,
} from "./molecules";
import data from "../assets/common.json";
import Link from "next/link";
import Image from "next/image";

const HomeAboutSec = ({
  title,
  description,
  link,
  imageUrl,
  headingClass,
  headingSize,
}) => {
  return (
    <>
      <Layout className="mb-16 md:mb-32">
        {imageUrl && <BgImgWrap />}
        <ContentContainer>
          {/* <Stack> */}
          <AboutContainer>
            <AboutContent>
              <h2
                className={`text-black text-[32px] leading-[32px] lg:text-6xl font-extrabold mb-8 ${
                  headingClass ? headingClass : ""
                }`}
                size={headingSize}
              >
                {title}
              </h2>
              <TP style={{ maxWidth: "520px" }}>{description}</TP>
            </AboutContent>
            <AboutRight>
              <div className="w-full h-[370px] md:h-[505px] relative overflow-hidden">
                <Image
                  loading="lazy"
                  src={imageUrl}
                  alt={title}
                  fill
                  objectFit="cover"
                  className="rounded-3xl w-full h-full"
                />
              </div>
              {link && (
                <>
                  <Link prefetch={false} href={link}>
                    <LearnMoreBtn className="learnMore">
                      {data.learnMore}
                    </LearnMoreBtn>
                  </Link>
                  <RoundShape />
                </>
              )}
            </AboutRight>
          </AboutContainer>
          {/* </Stack> */}
        </ContentContainer>
      </Layout>
    </>
  );
};

export default HomeAboutSec;
